// This consists of private routes
//@ts-nocheck

import { lazy } from "react";

const ApplicantProfileUpdate = lazy(
  () => import("../components/applicants/profile/profileUpdate")
);
const ApplicantProfile = lazy(
  () => import("../components/applicants/profile/profileView")
);
const ThreadForm = lazy(
  () => import("../components/applicants/threads/threadForm")
);
const ThreadsList = lazy(
  () => import("../components/applicants/threads/threadsList")
);
const RegistrationHistory = lazy(
  () => import("../components/applicants/history/registrationHistory")
);

const Reports = lazy(() => import("../components/staff/reports"));
const ApplicantsNewList = lazy(
  () => import("../components/staff/dashboard/applicants")
);
const CompanyProfileUpdate = lazy(
  () => import("../components/companies/profile/profileUpdate")
);
const CompanyProfile = lazy(
  () => import("../components/companies/profile/profileView")
);

const SlotsForm = lazy(() => import("../components/interview/slots/slotsForm"));
const SlotRegistrations = lazy(
  () => import("../components/interview/slots/slotRegistrations")
);
const InstructionsForm = lazy(
  () => import("../components/interview/instructions/InstructionsForm")
);
const SlotsList = lazy(() => import("../components/interview/slots/slotsList"));
const ResultsForm = lazy(
  () => import("../components/interview/results/resultsForm")
);
const ResultsUpdateForm = lazy(
  () => import("../components/interview/results/resultsUpdateForm")
);
const ResultsView = lazy(
  () => import("../components/interview/results/resultsView")
);
const SecondResultsForm = lazy(
  () => import("../components/interview/secondResults/resultsForm")
);

const SecondResultsUpdateForm = lazy(
  () => import("../components/interview/secondResults/resultsUpdateForm")
);
const SecondResultsView = lazy(
  () => import("../components/interview/secondResults/resultsView")
);
const InvitationsList = lazy(() => import("../components/invitations/"));
const InviteesList = lazy(
  () => import("../components/invitations/InviteesList")
);
const EvaluationForm = lazy(
  () => import("../components/evaluation/evaluationForm")
);
const ActivityEvaluationForm = lazy(
  () => import("../components/evaluation/evaluationForm/activityEvaluationForm")
);
const MultiInternsEvaluation = lazy(
  () =>
    import("../components/evaluation/companyEvaluation/MultiInternsEvaluation")
);
const CompanyEvaluationForm = lazy(
  () =>
    import("../components/evaluation/companyEvaluation/companyEvaluationForm")
);
const StaffSupervisorAddEvaluationForm = lazy(
  () =>
    import("../components/supervisors/staffSupervisorEvaluationForm/addForm")
);
const StaffSupervisorUpdateEvaluationForm = lazy(
  () =>
    import("../components/supervisors/staffSupervisorEvaluationForm/updateForm")
);

const StaffSupervisorProgramViewEvaluationForm = lazy(
  () =>
    import(
      "../components/supervisors/staffSupervisorEvaluationForm/viewForm/programView"
    )
);

const StaffSupervisorViewEvaluationForm = lazy(
  () =>
    import(
      "../components/supervisors/staffSupervisorEvaluationForm/viewForm/supervisorView"
    )
);
const CompanyEvaluationView = lazy(
  () =>
    import("../components/evaluation/companyEvaluation/companyEvaluationView")
);
const ParentProfile = lazy(
  () => import("../components/parents/profile/profileView")
);
const ParentProfileUpdate = lazy(
  () => import("../components/parents/profile/profileUpdate")
);
const EvaluationUpdateForm = lazy(
  () => import("../components/evaluation/evaluationUpdateForm")
);
const EvaluationView = lazy(
  () => import("../components/evaluation/evaluationView")
);
const ACtivityEvaluationView = lazy(
  () => import("../components/evaluation/evaluationView/activityEvaluationView")
);
const ProgramGroupForm = lazy(
  () => import("../components/programs/programGroup")
);
const ProgramGroupView = lazy(
  () => import("../components/programs/programGroup/programGroupView")
);
const ProgramGroupUpdateForm = lazy(
  () => import("../components/programs/programGroup/programGroupUpdate")
);
const ProgramGroupList = lazy(
  () => import("../components/programs/programGroup/programGroupList")
);
const ProgramWorkshopsList = lazy(
  () => import("../components/programs/Workshops")
);
const ChildrenList = lazy(
  () => import("../components/parents/children/childrenList")
);
const ChildrenLink = lazy(
  () => import("../components/parents/children/childrenLink")
);
const SupervisorAddVisitForm = lazy(
  () => import("../components/supervisors/supervisorVisitForm/addForm/")
);
// const ActivityForm = lazy(
//   () => import("../components/activities/activityForm")
// );
// const ActivityUpdateForm = lazy(
//   () => import("../components/activities/activitiesUpdate")
// );
// Program components

const ProgramForm = lazy(() => import("../components/programs/programForm"));
const Attendance = lazy(() => import("../components/programs/attendance"));
// const activityAttendance = lazy(
//   () => import("../components/activities/activityAttendance")
// );
const NewSupervisorsAdd = lazy(
  () => import("../components/programs/supervisors/NewSupervisorsAdd")
);
const SupervisorsList = lazy(
  () => import("../components/programs/supervisors")
);

const ProgramMonthsForm = lazy(
  () => import("../components/programs/programForm/ProgramMonthsForm")
);
const RegistrationForm = lazy(
  () =>
    import(
      "../components/programs/programDetails/sections/ApplicantSection/RegistrationSteps"
    )
);

const ParentRegistrationForm = lazy(
  () =>
    import(
      "../components/programs/programDetails/sections/ApplicantSection/RegistrationStepsParent"
    )
);

const ParentBasket = lazy(
  () =>
    import(
      "../components/programs/programDetails/sections/ParentSection/ParentPaymentPage"
    )
);
const PaymentMethodForm = lazy(
  () => import("../components/programs/programDetails/PaymentMethodForm")
);
const SupervisorUpdateVisitForm = lazy(
  () => import("../components/supervisors/supervisorVisitForm/updateForm/")
);

const SupervisorViewVisitForm = lazy(
  () => import("../components/supervisors/supervisorVisitForm/viewAllForms/")
);
const SupervisorViewVisitFormDetails = lazy(
  () => import("../components/supervisors/supervisorVisitForm/viewForm")
);
const AddBranches = lazy(
  () => import("../components/requests/requestForm/BranchRequest")
);
const InternsList = lazy(
  () => import("../components/requests/requestBranches/internsList")
);
const CreditList = lazy(
  () => import("../components/staff/dashboard/applicants/credits/CreditList")
);
const RequestForm = lazy(() => import("../components/requests/requestForm"));
const RequestUpdateForm = lazy(
  () => import("../components/requests/RequestUpdate")
);
const ApplicantShiftView = lazy(
  () =>
    import(
      "../components/community-service/profile/profileView/ApplicantShiftView"
    )
);
const DarbiSurvey = lazy(
  () =>
    import(
      "../components/programs/programDetails/sections/ApplicantSection/DarbiSurvey"
    )
);

const Darbi2Survey = lazy(
  () =>
    import(
      "../components/programs/programDetails/sections/ApplicantSection/Darbi2Survey"
    )
);

const DarbiExitSurvey = lazy(
  () =>
    import(
      "../components/programs/programDetails/sections/ApplicantSection/DarbiExitSurvey"
    )
);

const Darbi2ExitSurvey = lazy(
  () =>
    import(
      "../components/programs/programDetails/sections/ApplicantSection/Darbi2ExitSurvey"
    )
);
const CompanyExitSurvey = lazy(
  () => import("../components/requests/RequestsList/ExitSurvey")
);
const RequestBranches = lazy(
  () => import("../components/requests/requestBranches")
);
const RespondedThreads = lazy(
  () =>
    import("../components/staff/dashboard/threads/threadsList/RespondedList")
);
const NotRespondedThreads = lazy(
  () =>
    import("../components/staff/dashboard/threads/threadsList/NotRespondedList")
);

const RegistrationsList = lazy(
  () => import("../components/staff/dashboard/registrations")
);

const ActivityRegistrationsList = lazy(
  () => import("../components/staff/dashboard/activities")
);
const StaffProfile = lazy(
  () => import("../components/staff/profile/profileView")
);
const StaffProfileUpdate = lazy(
  () => import("../components/staff/profile/profileUpdate")
);
const AttendanceHistory = lazy(
  () => import("../components/applicants/history/attendanceHistory")
);
const RequestsList = lazy(() => import("../components/requests/RequestsList"));
const CommunityCentersList = lazy(
  () =>
    import(
      "../components/staff/dashboard/communityCenters/CommunityCentersList"
    )
);
const CommunityCenterInvitationsList = lazy(
  () => import("../components/community-service/invitations")
);
const CommunityCenterInviteesList = lazy(
  () => import("../components/community-service/invitations/InviteesList")
);
const CommunityCenterProfileUpdate = lazy(
  () => import("../components/community-service/profile/profileUpdate")
);
const CommnunityCenterProfile = lazy(
  () => import("../components/community-service/profile/profileView")
);
const SupervisorProfile = lazy(
  () => import("../components/supervisors/profile/ProfileView")
);
const SupervisorHistory = lazy(
  () => import("../components/supervisors/history")
);
const SupervisorProfileUpdate = lazy(
  () => import("../components/supervisors/profile/profileUpdate")
);
const Supervisors = lazy(
  () => import("../components/staff/dashboard/supervisors")
);
const ThreadDetails = lazy(
  () => import("../components/applicants/threads/threadDetails")
);
const CoreProgram = lazy(() => import("../components/programs/coreProgram"));
const NewInitiative = lazy(() => import("../components/LSV/NewInitiative"));
const RelatedDocs = lazy(() => import("../components/LSV/RelatedDocs"));
const Roles = lazy(() => import("../components/LSV/Roles"));
const Shifts = lazy(() => import("../components/LSV/Shifts"));
const RelatedDocsForm = lazy(
  () => import("../components/LSV/RelatedDocs/RelatedDocsForm")
);
const ShiftForm = lazy(() => import("../components/LSV/Shifts/ShiftForm"));
const RolesForm = lazy(() => import("../components/LSV/Roles/RolesForm"));
const UsherProfile = lazy(() => import("../components/LSV/Ushers/Profile"));
const UsherHistory = lazy(() => import("../components/LSV/Ushers/History"));
const ManagingInitiativesList = lazy(
  () => import("../components/LSV/Invitations/InvitationsList")
);
const LSVInterviewSlotsList = lazy(
  () => import("../components/LSV/Interview/InterviewSlots")
);
const LSVInterviewSlotsForm = lazy(
  () => import("../components/LSV/Interview/InterviewSlots/NewSlotsForm")
);
const LSVInterviewSlotsBookingForm = lazy(
  () => import("../components/LSV/Interview/InterviewSlots/BookSlotForm")
);
const GuideStatusList = lazy(() => import("../components/LSV/GuideStatus"));
const GuideStatusForm = lazy(
  () => import("../components/LSV/GuideStatus/GuideStatusForm")
);
const CommunicationHistoryList = lazy(
  () => import("../components/LSV/CommunicationHistory")
);
const VolunteerRequestsList = lazy(
  () =>
    import(
      "../components/community-service/volunteer-request/VolunteerRequestList"
    )
);
const SMJReports = lazy(() => import("../components/staff/smj_reports"));
const OrderList = lazy(() => import("../components/programs/ecommerce"));
const VolunteerRequestCreateForm = lazy(
  () =>
    import(
      "../components/community-service/volunteer-request/request-form/volunteerRequestCreateForm"
    )
);
const VolunteerRequestUpdateFormNew = lazy(
  () =>
    import(
      "../components/community-service/volunteer-request/request-form/volunteerRequestUpdateForm"
    )
);
const EditInitiativeGeneralDetails = lazy(
  () => import("../components/LSV/EditInitiative/GeneralDetails")
);

const ManualHoursList = lazy(
  () => import("../components/staff/dashboard/manualHours")
);
const EventsList = lazy(
  () => import("../components/events/staff/EventsRegistrationsList")
);
const EventForm = lazy(
  () => import("../components/events/staff/EventForm/index")
);
const EventRegistrationForm = lazy(
  () => import("../components/events/eventRegistration/EventRegistrationForm")
);
const EventRegistrationSuccess = lazy(
  () => import("../components/events/EventSuccessRegistration.jsx")
);
const EventRegistrationDetails = lazy(
  () => import("../components/events/EventRegistrationDetails.jsx")
);
const ProgramCustomPricing = lazy(
  () => import("../components/programs/customPricing")
);
const RegisterApplicants = lazy(
  () => import("../components/programs/registerApplicants")
);
const RegistrationSummary = lazy(
  () => import("../components/staff/dashboard/staffDashboard")
);

// Authentication/settings
const AccountSettings = lazy(() => import("../components/Auth/Settings"));

export const authenticatedRoutes = [
  {
    path: "applicant/:userID/profile/update",
    Component: ApplicantProfileUpdate,
    key: "applicant-profile-update",
  },
  {
    path: "applicant/:userID/profile",
    Component: ApplicantProfile,
    key: "applicant-profile",
  },
  {
    path: "applicant/:userID/threads/new",
    Component: ThreadForm,
    key: "thread-form",
  },
  {
    path: "applicant/:userID/threads",
    Component: ThreadsList,
    key: "thread-list",
  },
  {
    path: "applicant/:userID/history",
    Component: RegistrationHistory,
    key: "reg-history",
  },
  {
    path: "reports",
    Component: Reports,
    key: "reports",
  },
  {
    path: "applicants",
    Component: ApplicantsNewList,
    key: "applicants",
  },
  {
    path: "activities/:activityID/invitation",
    Component: ApplicantsNewList,
    key: "activities-invitation-id",
  },
  {
    path: "company/:userID/profile/update",
    Component: CompanyProfileUpdate,
    key: "company-profile-update",
  },
  {
    path: "company/:userID/profile",
    Component: CompanyProfile,
    key: "company-profile",
  },
  {
    path: "programs/:programID/slots/add",
    Component: SlotsForm,
    key: "slots-form",
  },
  {
    path: "programs/:programID/slots/:slotID",
    Component: SlotRegistrations,
    key: "program-slot",
  },
  {
    path: "programs/:programID/instructions",
    Component: InstructionsForm,
    key: "program-instruction",
  },
  {
    path: "programs/:programID/slots",
    Component: SlotsList,
    key: "program-slot-list",
  },
  {
    path: "programs/:programID/custom-pricing",
    Component: ProgramCustomPricing,
    key: "program-custom-pricing",
  },
  {
    path: "programs/:programID/register-applicants",
    Component: RegisterApplicants,
    key: "program-register-applicants",
  },
  {
    path: "registrations/:registrationID/results/add",
    Component: ResultsForm,
    key: "results-form",
  },
  {
    path: "registrations/:registrationID/results/update",
    Component: ResultsUpdateForm,
    key: "result-update-form",
  },
  {
    path: "registrations/:registrationID/results",
    Component: ResultsView,
    key: "result-view",
  },
  {
    path: "registrations/:registrationID/second_results/add",
    Component: SecondResultsForm,
    key: "second-result",
  },
  {
    path: "registrations/:registrationID/second_results/update",
    Component: SecondResultsUpdateForm,
    key: "second-result-update-form",
  },
  {
    path: "registrations/:registrationID/second_results",
    Component: SecondResultsView,
    key: "second-result-view-view",
  },
  {
    path: "invitations",
    Component: InvitationsList,
    key: "invitations",
  },
  {
    path: "invitees",
    Component: InviteesList,
    key: "invitees",
  },
  {
    path: "registrations/:registrationID/evaluation/add",
    Component: EvaluationForm,
    key: "reg-eval",
  },
  {
    path: "registrations/:registrationID/activityevaluation/add",
    Component: ActivityEvaluationForm,
    key: "act-eval",
  },
  {
    path: "registrations/:registrationID/company_evaluation/add",
    Component: CompanyEvaluationForm,
    key: "com-eval",
  },
  {
    path: "requests/:requestID/branches/:branchID/interns_evaluation",
    Component: MultiInternsEvaluation,
    key: "multien",
  },
  {
    path: "registrations/:registrationID/company_evaluation",
    Component: CompanyEvaluationView,
    key: "com-eval",
  },
  {
    path: "program/:programID/supervisor/evaluation/add",
    Component: StaffSupervisorAddEvaluationForm,
    key: "staff-super-add",
  },
  {
    path: "program/:programID/supervisor/evaluation/update",
    Component: StaffSupervisorUpdateEvaluationForm,
    key: "staff-1",
  },
  {
    path: "supervisor/evaluation/view",
    Component: StaffSupervisorViewEvaluationForm,
    key: "staff-2",
  },
  {
    path: "program/:programID/supervisor/evaluations/view",
    Component: StaffSupervisorProgramViewEvaluationForm,
    key: "staff-3",
  },
  {
    path: "registrations/:registrationID/evaluation/update",
    Component: EvaluationUpdateForm,
    key: "staff-4",
  },
  {
    path: "registrations/:registrationID/evaluation",
    Component: EvaluationView,
    key: "staff-5",
  },
  {
    path: "registrations/:registrationID/activityevaluation",
    Component: ACtivityEvaluationView,
    key: "staff-6",
  },
  {
    path: "parent/:userID/profile/update",
    Component: ParentProfileUpdate,
    key: "staff-7",
  },
  {
    path: "parent/:userID/profile",
    Component: ParentProfile,
    key: "staff-8",
  },
  {
    path: "parent/:userID/children/link",
    Component: ChildrenLink,
    key: "staff-9",
  },
  {
    path: "parent/:userID/children",
    Component: ChildrenList,
    key: "staff-10",
  },
  {
    path: "programs/:programID/groups/add",
    Component: ProgramGroupForm,
    key: "staff-11",
  },
  {
    path: "programs/:programID/group",
    Component: ProgramGroupView,
    key: "staff-12",
  },
  {
    path: "programs/:programID/groups/update",
    Component: ProgramGroupUpdateForm,
    key: "staff-13",
  },
  {
    path: "programs/:programID/groups",
    Component: ProgramGroupList,
    key: "staff-14",
  },
  {
    path: "programs/:programID/workshops",
    Component: ProgramWorkshopsList,
    key: "staff-15",
  },
  {
    path: "supervisors/add",
    Component: NewSupervisorsAdd,
    key: "staff-16",
  },
  {
    path: "programs/:programID/supervisors",
    Component: SupervisorsList,
    key: "staff-17",
  },
  {
    path: "programs/:programID/attendance",
    Component: Attendance,
    key: "staff-16",
  },
  // {
  //   path: "activities/:activityID/attendance",
  //   Component: activityAttendance,
  //   key: "staff-17",
  // },
  // {
  //   path: "activities/add",
  //   Component: ActivityForm,
  //   key: "staff-18",
  // },
  {
    path: "create-update/program/:programID?",
    Component: ProgramForm,
    key: "staff-19",
  },

  // {
  //   path: "activities/:activityID/update",
  //   Component: ActivityUpdateForm,
  //   key: "staff-21",
  // },
  {
    path: "programs/:programID/visit/add",
    Component: SupervisorAddVisitForm,
    key: "staff-22",
  },
  {
    path: "programs/:programID/visit/view",
    Component: SupervisorViewVisitForm,
    key: "staff-23",
  },
  {
    path: "programs/visit/:formID/view",
    Component: SupervisorViewVisitFormDetails,
    key: "staff-24",
  },
  {
    path: "programs/:programID/visit/update",
    Component: SupervisorUpdateVisitForm,
    key: "staff-25",
  },
  {
    path: "programs/:programID/register",
    Component: RegistrationForm,
    key: "staff-27",
  },
  {
    path: "programs/:programID/register-smj/:month",
    Component: RegistrationForm,
    key: "staff-271",
  },
  {
    path: "programs/:programID/register/:registrationID/payment",
    Component: PaymentMethodForm,
    key: "staff-26",
  },
  {
    path: "programs/:programID/parent/register",
    Component: ParentRegistrationForm,
    key: "staff-28",
  },
  {
    path: "payment/parent/basket",
    Component: ParentBasket,
    key: "staff-29",
  },
  {
    path: "credits",
    Component: CreditList,
    key: "staff-30",
  },
  {
    path: "programs/:programID/darbi_survey",
    Component: DarbiSurvey,
    key: "staff-31",
  },
  {
    path: "programs/:programID/darbi_exit_survey",
    Component: DarbiExitSurvey,
    key: "staff-32",
  },
  {
    path: "programs/:programID/darbi_2_survey",
    Component: Darbi2Survey,
    key: "staff-33",
  },
  {
    path: "programs/:programID/darbi_2_exit_survey",
    Component: Darbi2ExitSurvey,
    key: "staff-34",
  },
  {
    path: "programs/:programID/company_exit_survey",
    Component: CompanyExitSurvey,
    key: "staff-35",
  },
  {
    path: "register/shift/:shiftID/details",
    Component: ApplicantShiftView,
    key: "staff-37",
  },
  {
    path: "requests/add",
    Component: RequestForm,
    key: "staff-38",
  },
  {
    path: "requests/:requestID/update",
    Component: RequestUpdateForm,
    key: "staff-39",
  },
  {
    path: "requests/:requestID/branches/:branchID/interns",
    Component: InternsList,
    key: "staff-40",
  },
  {
    path: "requests/:requestID/branches/add",
    Component: AddBranches,
    key: "staff-41",
  },
  {
    path: "requests/:requestID/branches",
    Component: RequestBranches,
    key: "staff-42",
  },
  {
    path: "staff/:userID/profile/update",
    Component: StaffProfileUpdate,
    key: "staff-43",
  },
  {
    path: "applicant/:programID/attendance/:applicantID",
    Component: AttendanceHistory,
    key: "staff-44",
  },
  {
    path: "staff/:userID/profile",
    Component: StaffProfile,
    key: "staff-45",
  },
  {
    path: "registrations/activities",
    Component: ActivityRegistrationsList,
    key: "staff-46",
  },
  {
    path: "registrations",
    Component: RegistrationsList,
    key: "staff-47",
  },
  {
    path: "threads/responded",
    Component: RespondedThreads,
    key: "staff-48",
  },
  {
    path: "threads/not/responded",
    Component: NotRespondedThreads,
    key: "staff-49",
  },
  {
    path: "requests",
    Component: RequestsList,
    key: "staff-50",
  },
  {
    path: "threads/:threadID",
    Component: ThreadDetails,
    key: "staff-51",
  },
  {
    path: "supervisors",
    Component: Supervisors,
    key: "staff-52",
  },
  {
    path: "supervisor/:userID/profile/update",
    Component: SupervisorProfileUpdate,
    key: "staff-53",
  },
  {
    path: "supervisor/:userID/history",
    Component: SupervisorHistory,
    key: "staff-54",
  },
  {
    path: "supervisor/:userID/profile",
    Component: SupervisorProfile,
    key: "staff-55",
  },
  {
    path: "community_center/invitations",
    Component: CommunityCenterInvitationsList,
    key: "staff-56",
  },
  {
    path: "community_center/list",
    Component: CommunityCentersList,
    key: "staff-57",
  },
  {
    path: "community_center/invitees",
    Component: CommunityCenterInviteesList,
    key: "staff-58",
  },
  {
    path: "community_center/:userID/profile/update",
    Component: CommunityCenterProfileUpdate,
    key: "staff-60",
  },
  {
    path: "community_center/:userID/profile",
    Component: CommnunityCenterProfile,
    key: "staff-61",
  },
  {
    path: "community_center/:userID/requests/add",
    Component: VolunteerRequestCreateForm,
    key: "staff-62",
  },
  {
    path: "volunteer_requests/:requestID/update",
    Component: VolunteerRequestUpdateFormNew,
    key: "staff-63",
  },
  {
    path: "volunteer_requests",
    Component: VolunteerRequestsList,
    key: "staff-64",
  },
  {
    path: "smj_reports",
    Component: SMJReports,
    key: "staff-65",
  },
  {
    path: "communication/:initiativeUsherID/history",
    Component: CommunicationHistoryList,
    key: "staff-67",
  },
  {
    path: "guide_status/add",
    Component: GuideStatusForm,
    key: "staff-68",
  },
  {
    path: "guide_status",
    Component: GuideStatusList,
    key: "staff-69",
  },
  {
    path: "LSV/slots/book",
    Component: LSVInterviewSlotsBookingForm,
    key: "staff-74",
  },
  {
    path: "LSV/slots/add",
    Component: LSVInterviewSlotsForm,
    key: "staff-75",
  },
  {
    path: "LSV/slots",
    Component: LSVInterviewSlotsList,
    key: "staff-76",
  },
  {
    path: "initiatives/list/manage",
    Component: ManagingInitiativesList,
    key: "staff-78",
  },
  {
    path: "initiatives/:initiativeID/docs/add",
    Component: RelatedDocsForm,
    key: "staff-79",
  },
  {
    path: "initiatives/:initiativeID/shifts/add",
    Component: ShiftForm,
    key: "staff-80",
  },
  {
    path: "initiatives/:initiativeID/roles/add",
    Component: RolesForm,
    key: "staff-81",
  },
  {
    path: "initiatives/:initiativeID/roles",
    Component: Roles,
    key: "staff-82",
  },
  {
    path: "initiatives/:initiativeID/shifts",
    Component: Shifts,
    key: "staff-83",
  },
  {
    path: "initiatives/:initiativeID/docs",
    Component: RelatedDocs,
    key: "staff-84",
  },
  {
    path: "initiatives/:initiativeID/update",
    Component: EditInitiativeGeneralDetails,
    key: "staff-85",
  },
  {
    path: "initiative/add",
    Component: NewInitiative,
    key: "staff-86",
  },
  {
    path: "ushers/:usherID/profile",
    Component: UsherProfile,
    key: "staff-87",
  },
  {
    path: "ushers/:usherID/history",
    Component: UsherHistory,
    key: "staff-88",
  },
  {
    path: "orders",
    Component: OrderList,
    key: "staff-90",
  },
  {
    path: "core/programs",
    Component: CoreProgram,
    key: "staff-91",
  },
  {
    path: "programs/:programID/months",
    Component: ProgramMonthsForm,
    key: "staff-92",
  },
  {
    path: "staff/manual-hours",
    Component: ManualHoursList,
    key: "staff-93",
  },
  {
    path: "staff/events",
    Component: EventsList,
    key: "staff-94",
  },
  {
    path: "staff/events/create-update/:eventID?",
    Component: EventForm,
    key: "staff-94",
  },
  {
    path: "events/register/:slug",
    Component: EventRegistrationForm,
    key: "applicant-event-registration",
  },
  {
    path: "events/register/:regNumber/success",
    Component: EventRegistrationSuccess,
    key: "event-registration-success",
  },
  {
    path: "event/registration/details/:id",
    Component: EventRegistrationDetails,
    key: "event-registration-details",
  },

  // Auth related
  {
    path: "account/settings",
    Component: AccountSettings,
    key: "account-settings",
  },
  // {
  //   path: "event/registration/payment/:id",
  //   Component: EventPayment,
  //   key: "event-registration-payment",
  // },
  {
    path: "staff/dashboard",
    Component: RegistrationSummary,
    key: "staff-93",
  },
];
